import React from "react"

const wantToHelp = 'Want to help?';
const shareYourExperience = 'Share your experience'
const surveyCopy =`
  We want to learn more about you so that we have your story in the room as we’re building.
  We’re collecting data from underrepresented students and professionals to understand the habits and motivations for seeking guidance and sharing experiences. 
`;

const studentSurveyUrl = `http://bit.ly/gwx_student`;
const professionalSurveyUrl = `http://bit.ly/gwx_professional`;

const SurveryRequest = () => (
    <section className={'survey'}>
      <div className={'container'}>
        <h2 className={'question'}>{wantToHelp}</h2>
        <h2 className={'skinny'}>
          {shareYourExperience}
        </h2>
        <p className={'copy'}>
          {surveyCopy}
        </p>
        <div className={'buttons-container'}>
          <a href={professionalSurveyUrl}>I am a Professional</a>
          <a href={studentSurveyUrl}>I am a Student</a>
        </div>
        <p className={'small'}> *The survey takes less than 3 minutes to complete*</p>

      </div>
    </section>
);

export default SurveryRequest;
