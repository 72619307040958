import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import SurveyRequest from '../components/survey-request'

const ThankYouPage = () => (
  <Layout>
    <SEO title='Thank You' />
    <div className={'container'} style={{marginBottom:'10vh'}}>
      <h1>Thank you.</h1>
      <p className={'copy'}>
      </p>
    </div>
    <SurveyRequest/>
  </Layout>
);

export default ThankYouPage
